.blog_card {
    border: 1px solid #cccccc;
    border-radius: 10px;
    height: 122px;
    overflow: hidden;
    margin-bottom: 20px;
}

.blog_img {
    border-radius: 10px 0px 0px 10px;
}

.card_detail {
    padding: 10px;
    height: 100%;
}

.card_detail h1 {
    font-size: 20px;
    color: #000000;
    font-weight: 700;
    margin: 0px;
}

.card_detail .description {
    font-size: 14px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.card_action{
    display: flex;
    justify-content: space-around;
    height: 100%;
    align-items: center;
}

.image_upload{
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    height: 200px;
}

.image_upload label{
    border: 2px dotted #cccccc;
    height: 100% !important;
    width: 100% !important;
    border-radius: 10px;
    cursor: pointer;
    display: flex !important;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.image_upload p {
    margin: 0px;
    color: #cccccc;
    font-size: 14px;
    font-weight: 600;
}

.input_label{
    color: #000000 !important;
    margin-bottom: 5px;
    font-weight: 600 !important;
}

.input_textarea{
    background-color: transparent;
    border: 1px solid #b5bac4;
    border-radius: 5px;
    width: 100%;
}