.authlayout_main {
  display: flex;
}

.sidebar_link{
  text-decoration: none;
  color: #000;
  font-weight: 700;
  height: fit-content;
}

.inner_link{
  display: flex;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  margin: 0px 10px;
  border-radius: 5px;
}

.active_sidebar_link{
  background-color: #fff;
  text-decoration: none;
}

.logo_text{
  font-size: 20px;
  text-transform: uppercase;
  color: #000;
  font-weight: 700;
  letter-spacing: 1px;
}

.page_main{
  background-color: #ecf2ff;
}

.page_header_main{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.page_title{
  font-size: 22px;
  font-weight: 700;
  color: #000;
}

.tox-notification{
  display: none !important;
}

.header_main .MuiOutlinedInput-notchedOutline {
  border-color: #FFFFFF !important;
}

.header_main .MuiSvgIcon-fontSizeMedium {
  color: #FFFFFF !important;
}

.header_main .MuiOutlinedInput-root{
  color: #FFFFFF;
}